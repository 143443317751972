import { createContext } from 'react';
import { FieldType } from '../components/Home/registrationForm';

interface DataState {
  language: string;
}

interface SendFromRegisterResult {
  ok: boolean,
  data?: FieldType,
  error?: string,
  message?: string,
}

interface Props {
  dataState: DataState;
  sendFormRegister: (data: FieldType) => Promise<SendFromRegisterResult>;
  setLanguage: (language: string) => void;
}

export const DataContext = createContext<Props>({} as Props);
