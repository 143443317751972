import React from 'react';

import Union from '../../assets/img/Union.svg';

import './mobileHeader.css';

export const MobileHeader: React.FC = () => {
  return (
    <>
      <img src={ Union } alt="" className="header-image-svg" />
      <div className="mobile-header-container">
        <div className="header-image-back" />
      </div>
    </>
  );
};
