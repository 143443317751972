
const dict = {
  home: {
    es: {
      title: 'OnTour',
      welcome: 'Registrate al programa On Tour y accede a ofertas únicas para turistas.',
      instructions: 'Rellena el formulario y recibe tu tarjeta digital para disfrutar de grandes descuentos.',
      name: 'Nombre',
      errorName: 'Por favor ingrese su nombre',
      lastName: 'Apellido',
      errorLastName: 'Por favor ingrese su apellido',
      email: 'Correo Electrónico',
      errorEmail: 'Por favor ingrese su Correo Electrónico',
      errorEmail2: 'Por favor ingrese un correo válido',
      document: 'N° Pasaporte',
      errorDocument: 'Por favor ingrese su Num identificación',
      arrival: 'Fecha de Llegada',
      errorArrival: 'Por favor ingrese su fecha de llegada',
      country: 'País de Origen',
      errorCountry: 'Por favor ingrese su país de origen',
      searchCountry: 'Buscar país',
      genre: 'Género',
      male: 'Masculino',
      female: 'Femenino',
      other: 'Otro',
      errorGenre: 'Por favor seleccione un género',
      promo: 'Acepto recibir ofertas y promociones.',
      terms: 'Acepto términos y condiciones.',
      errorTerms: 'Por favor acepte los términos y condiciones',
      submit: 'Registrate',
    },
    en: {
      title: 'OnTour',
      welcome: 'Sign up for the On Tour program and access unique offers for tourists.',
      instructions: 'Fill out the form and receive your digital card to enjoy great discounts.',
      name: 'Name',
      errorName: 'Please enter your name',
      lastName: 'Last Name',
      errorLastName: 'Please enter your last name',
      email: 'Email',
      errorEmail: 'Please enter your email',
      errorEmail2: 'Please enter a valid email',
      document: 'Passport N°',
      errorDocument: 'Please enter your identification number',
      arrival: 'Arrival Date',
      errorArrival: 'Please enter your arrival date',
      country: 'Country',
      errorCountry: 'Please enter your country',
      searchCountry: 'Search country',
      genre: 'Gender',
      male: 'Male',
      female: 'Female',
      other: 'Other',
      errorGenre: 'Please select a genre',
      promo: 'I accept to receive offers and promotions.',
      terms: 'I accept terms and conditions.',
      errorTerms: 'Please accept the terms and conditions',
      submit: 'Sign up',
    },
    pt: {
      title: 'OnTour',
      welcome: 'Inscreva-se no programa On Tour e acesse ofertas únicas para turistas.',
      instructions: 'Preencha o formulário e receba seu cartão digital para desfrutar de grandes descontos.',
      name: 'Nome',
      errorName: 'Por favor, insira seu nome',
      lastName: 'Sobrenome',
      errorLastName: 'Por favor insira seu sobrenome',
      email: 'E-mail',
      errorEmail: 'Por favor insira seu e-mail',
      errorEmail2: 'Por favor insira um e-mail válido',
      document: 'N° Passaporte',
      errorDocument: 'Por favor insira seu número de identificação',
      arrival: 'Data de Chegada',
      errorArrival: 'Por favor insira sua data de chegada',
      country: 'País de Origem',
      errorCountry: 'Por favor insira seu país de origem',
      searchCountry: 'Procurar país',
      genre: 'Gênero',
      male: 'Masculino',
      female: 'Feminino',
      other: 'Outro',
      errorGenre: 'Por favor selecione um gênero',
      promo: 'Aceito receber ofertas e promoções.',
      terms: 'Aceito os termos e condições.',
      errorTerms: 'Por favor aceite os termos e condições',
      submit: 'Inscrever-se',
    },
  },
  success: {
    es: {
      registerTitle: '¡Registro exitoso!',
      thanks: 'gracias por registrarte.',
      email: 'Te hemos enviado un correo de registro a ',
      important: 'Importante',
      importantInfo: 'Para obtener tu tarjeta, por favor sigue los pasos a continuación:',
      step1: 'Visita nuestro centro de atención al cliente:',
      step1Info1: 'Nivel 1, Costanera Center.',
      step1Info2: 'Lunes a Domingo, de Domingo a Jueves 10:00 a 20:30 hrs.',
      step2: 'Verifica tu identidad:',
      step2Info1: 'Presenta tu pasaporte o identificación utilizada en el registro.',
      step2Info2: 'Presenta el correo de confirmación que te hemos enviado.',
      setp3: 'Recepción de la tarjeta digital',
      setp3Info: 'Una vez verificada tu información, recibirás tu tarjeta de descuento digital en tu correo electrónico.',
    },
    en: {
      registerTitle: 'Successful registration!',
      thanks: 'thanks for registering.',
      email: 'We have sent you a registration email to ',
      important: 'Important',
      importantInfo: 'To get your card, please follow the steps below:',
      step1: 'Visit our customer service center:',
      step1Info1: 'Level 1, Costanera Center.',
      step1Info2: 'Monday to Sunday, from Sunday to Thursday 10:00 a 20:30 hrs.',
      step2: 'Verify your identity:',
      step2Info1: 'Present your passport or identification used in the registration.',
      step2Info2: 'Present the confirmation email we sent you.',
      setp3: 'Reception of the digital card',
      setp3Info: 'Once your information has been verified, you will receive your digital discount card in your email.',
    },
    pt: {
      registerTitle: 'Registro bem-sucedido!',
      thanks: 'obrigado por se registrar.',
      email: 'Enviamos um e-mail de registro para ',
      important: 'Importante',
      importantInfo: 'Para obter seu cartão, siga as etapas abaixo:',
      step1: 'Visite nosso centro de atendimento ao cliente:',
      step1Info1: 'Nível 1, Costanera Center.',
      step1Info2: 'Segunda a domingo, de domingo a quinta-feira das 10:00 às 20:30 hrs.',
      step2: 'Verifique sua identidade:',
      step2Info1: 'Apresente seu passaporte ou identificação usada no registro.',
      step2Info2: 'Apresente o e-mail de confirmação que enviamos a você.',
      setp3: 'Recepção do cartão digital',
      setp3Info: 'Depois que suas informações forem verificadas, você receberá seu cartão de desconto digital em seu e-mail.',
    },
  },
};

export default dict;
