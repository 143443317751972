import React from 'react';

import Logo from '../../assets/img/cenco_mall_wide.svg';

import './footer.css';

interface FooterProps {
  isVisible: boolean;
}

const CustomFooter: React.FC<FooterProps> = ({ isVisible }) => {

  const year = new Date().getFullYear();

  if (!isVisible) return null;

  return (
    <div className='custom-footer'>
      <p>@{ year } Cenco Malls. Todos los derechos reservados.</p>
      <div className='custom-footer-img'>
        <img src={ Logo } alt="cencomalls" />
      </div>
    </div>
  );
};

export default CustomFooter;
