import { useReducer } from 'react';
import { FieldType } from '../components/Home/registrationForm';
import { DataContext } from './DataContext';
import { dataReducer } from './dataReducer';
import { fetchApi } from '../utils/fetchApi';

const INITIAL_STATE = {
  language: 'es',
  fieldData: {},
};
interface Props {
  children: JSX.Element | JSX.Element[];
}

export const DataProvider = ({ children }: Props) => {
  const [dataState, dispatch] = useReducer(dataReducer, INITIAL_STATE);

  const sendFormRegister = async (data: FieldType) => {
    const { lang } = data;
    const resp = await fetchApi(`/client?lang=${lang}`, 'POST', data);
    return resp;
  };

  const setLanguage = (language: string) => {
    dispatch({ type: 'setLanguage', payload: language });
  };

  return (
    <DataContext.Provider value={{
      dataState,
      sendFormRegister,
      setLanguage,
    }}>
      {children}
    </DataContext.Provider>
  );

};
