import React, { useContext } from 'react';
import { RegistrationForm } from '../../components/Home/registrationForm';
import { MobileHeader } from '../../components/Header/mobileHeader';
import { DataContext } from '../../context/DataContext';
import dict from '../../dict';
import './home.css';

const RootPage: React.FC = () => {
  // Uso de useContext para obtener el estado del idioma
  const { dataState } = useContext(DataContext);
  const { language } = dataState;

  // Accede a los textos del diccionario dependiendo del idioma seleccionado
  const home = dict.home[language as keyof typeof dict.home];

  return (
    <div className='home-container'>
      <MobileHeader />
      <h1>{home.title}</h1>
      <h2>{home.welcome}</h2>
      <p>{home.instructions}</p>

      <div className="home-container-form">
        <RegistrationForm />
      </div>
    </div>
  );
};

export default RootPage;
