import { Route, Routes, useLocation } from 'react-router-dom'; // Ya no necesitas el <Router> aquí
import { ConfigProvider, Layout } from 'antd';
import './layout.css';
import HomePage from './../home';
import AboutPage from './../about';
import SuccessPage from './../success'; // Importar el nuevo componente de éxito
import CustomHeader from '../../components/Header';
import CustomSidebar from '../../components/Sidebar';
import CustomFooter from '../../components/Footer';

const MainLayout = () => {
  const location = useLocation(); // Hook para obtener la ruta actual
  const isSuccessPage = location.pathname === '/success'; // Detectar si estamos en la ruta de éxito

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins, sans-serif',
          colorBorder: 'transparent',
          borderRadius: 12,
          colorPrimary: '#240070',
        },
        components: {
          Layout: {
            colorBgLayout: 'transparent',
          },
        },
      }}
    >
      <Layout className='layout-main'>
        {/* Header fijo en la parte superior */}
        <CustomHeader isVisible={true} />

        {/* Layout del cuerpo */}
        <Layout className={`layout-body ${isSuccessPage ? 'success-page' : ''}`}>
          {/* Mostrar el Sidebar solo si no es la página de éxito */}
          {!isSuccessPage && <CustomSidebar isVisible={true} />}

          <Layout className="layout-content">
            {/* Contenido principal */}
            <Layout.Content>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/success" element={<SuccessPage />} /> {/* Ruta del éxito */}
              </Routes>
            </Layout.Content>
            {/* Footer */}
            {!isSuccessPage && (
              <Layout.Footer className='layout-footer'>
                <CustomFooter isVisible={true} />
              </Layout.Footer>
            )}

          </Layout>

        </Layout>

      </Layout>
    </ConfigProvider>
  );
};

export default MainLayout;
