import React, { useContext, useState } from 'react';
import { Form, Input, DatePicker, Select, Checkbox, Button, ConfigProvider, FormProps, message } from 'antd';
import ReactFlagsSelect from 'react-flags-select';

import { DataContext } from '../../context/DataContext';
import { useNavigate } from 'react-router-dom';
import dict from '../../dict';

const { Option } = Select;

export interface FieldType {
  name: string;
  lastname: string;
  email: string;
  dni: string;
  arrival: string;
  country: string;
  promo: boolean;
  terms: boolean;
  status: string;
  lang: string;
}

export const RegistrationForm: React.FC = () => {

  const { sendFormRegister, dataState } = useContext(DataContext);
  const { language } = dataState;

  const home = dict.home[language as keyof typeof dict.home];

  const [form] = Form.useForm();
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [selected, setSelected] = useState('');
  const [selectGenre, setSelectGenre] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const handleFocus = (field: string) => {
    setFocusedField(field);
  };

  const handleBlur = (field: string) => {
    if (!form.getFieldValue(field)) {
      setFocusedField(null);
    }
  };

  const handleChangeGenre = (value: string) => {
    setSelectGenre(value);
    form.setFieldsValue({ gender: value });
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    const sendClient = await sendFormRegister({
      ...values,
      status: 'created',
      lang: language,
    });
    if (!sendClient.ok) {
      message.error(`Error al enviar el formulario: ${sendClient.error}`);
    } else {
      message.success('Formulario enviado correctamente');
      form.resetFields();
      navigate('success', { state: { email: values.email } });
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    message.error(`Error al enviar el formulario: ${errorInfo}`);
  };

  // Función para verificar la validez del formulario
  const checkFormValidity = () => {
    try {
      const fieldsValues = form.getFieldsValue([
        'name',
        'lastname',
        'email',
        'dni',
        'arrival',
        'country',
        'gender',
        'terms',
      ]);

      const isFormFilled = Object.values(fieldsValues).every(value => value);

      setIsButtonDisabled(!isFormFilled); // Habilitar el botón solo si todo está lleno
    } catch (error) {
      console.error('Error al verificar la validez del formulario:', error);
      message.error('Error al verificar la validez del formulario');
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: {
            colorBgContainer: 'transparent',
            colorBorderSecondary: 'transparent',
            borderRadius: 8,
            colorBorder: '#8A91A4',
            hoverBorderColor: '#8A91A4',
            colorPrimary: '#240070',
            colorText: '#000',
            colorTextDisabled: '#D9D9D9',
            colorPrimaryHover: '#240070',
          },
          Select: {
            colorBgContainer: 'transparent',
            colorBorder: '#8A91A4',
            borderRadius: 8,
            colorPrimary: '#240070',
            colorText: '#000',
            colorTextDisabled: '#D9D9D9',
            colorPrimaryHover: '#240070',
          },
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="custom-form"
        onValuesChange={checkFormValidity}
      >
        <div className="form-row">
          <Form.Item
            name="name"
            className={`custom-form-item ${focusedField === 'name' ? 'focused' : ''}`}
            rules={[{ required: true, message: home.errorName }]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus('name')}
                onBlur={() => handleBlur('name')}
              />
              <label className="floating-label">{home.name}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="lastname"
            className={`custom-form-item ${focusedField === 'lastname' ? 'focused' : ''}`}
            rules={[
              { required: true, message: home.errorLastName },
            ]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus('lastname')}
                onBlur={() => handleBlur('lastname')}
              />
              <label className="floating-label">{home.lastName}</label>
            </div>
          </Form.Item>
        </div>

        <Form.Item
          name="email"
          className={`custom-form-item ${focusedField === 'email' ? 'focused' : ''}`}
          rules={[
            { required: true, message: home.errorEmail },
            { type: 'email', message: home.errorEmail2 },
          ]}
        >
          <div className="input-wrapper">
            <Input
              placeholder=" "
              onFocus={() => handleFocus('email')}
              onBlur={() => handleBlur('email')}
            />
            <label className="floating-label">{home.email}</label>
          </div>
        </Form.Item>

        <div className="form-row">
          <Form.Item
            name="dni"
            className={`custom-form-item ${focusedField === 'dni' ? 'focused' : ''}`}
            rules={[{ required: true, message: home.errorDocument }]}
          >
            <div className="input-wrapper">
              <Input
                placeholder=" "
                onFocus={() => handleFocus('dni')}
                onBlur={() => handleBlur('dni')}
              />
              <label className="floating-label">{home.document}</label>
            </div>
          </Form.Item>

          <Form.Item
            name="arrival"
            className={`custom-form-item ${
              focusedField === 'arrival' || form.getFieldValue('arrival') ? 'focused' : ''
            }`}
            rules={[{ required: true, message: home.errorArrival }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              placeholder=" "
              onFocus={() => handleFocus('arrival')}
              onBlur={() => handleBlur('arrival')}
              onChange={(date) => {
                form.setFieldsValue({ arrival: date });
                if (date) {
                  setFocusedField('arrival');
                } else {
                  setFocusedField(null);
                }
              }}
              popupClassName='custom-datepicker-dropdown'
              //getPopupContainer={(trigger) => trigger?.parentElement || document.body }
            />
            <label className="floating-label">{ home.arrival }</label>
          </Form.Item>
        </div>

        <div className="form-row">
          <Form.Item
            name="country"
            className={`item-flag custom-form-item ${focusedField === 'country' || selected ? 'focused' : ''}`}
            rules={[{ required: true, message: home.errorCountry }]}
          >
            <div
              onFocus={() => handleFocus('country')}
              onBlur={() => handleBlur('country')}
              className='home-custom-flag-select-container'
            >
              <ReactFlagsSelect
                className={`home-custom-flag-select ${selected ? 'country-selected' : ''}`}
                selected={selected}
                onSelect={(code) => {
                  setSelected(code);
                  form.setFieldsValue({ country: code });
                }}
                placeholder={selected ? '' : ' '}
                searchable={true}
                searchPlaceholder={home.searchCountry}
                selectButtonClassName='home-custom-flag-select-button'
              />
            </div>
            <label className="floating-label">{home.country}</label>
          </Form.Item>

          <Form.Item
            name="gender"
            className={`custom-form-item ${focusedField === 'gender' ? 'focused' : ''}`}
            rules={[{ required: true, message: home.errorGenre }]}
          >
            <div className="input-wrapper">
              <Select
                value={selectGenre}
                placeholder=" "
                onFocus={() => handleFocus('gender')}
                onBlur={() => handleBlur('gender')}
                className={`home-custom-select ${selectGenre ? 'gender-select' : ''}`}
                onChange={handleChangeGenre}
              >
                <Option value="masculino">{ home.male }</Option>
                <Option value="femenino">{ home.female }</Option>
                <Option value="otro">{ home.other }</Option>
              </Select>
              <label className="floating-label">{home.genre}</label>
            </div>
          </Form.Item>
        </div>

        <Form.Item
          name="promo"
          valuePropName="checked"
          className="custom-checkbox"
          initialValue={false}
        >
          <Checkbox>{home.promo}</Checkbox>
        </Form.Item>

        <Form.Item
          name="terms"
          valuePropName="checked"
          className="custom-checkbox"
          rules={[{ required: true, message: home.errorTerms }]}
          initialValue={false}
        >
          <Checkbox>
            {home.terms}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <div className="home-button-container">
            <Button
              type="primary"
              htmlType="submit"
              className={`submit-button ${isButtonDisabled ? 'button-disabled' : 'button-active' }`}
              disabled={isButtonDisabled} // Botón deshabilitado si no están todos los campos completos
            >
              {home.submit}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};
