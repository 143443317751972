/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { urls } from '../config/urls';

const BASE_URL = urls.api;

//connect to api with axios or fetch
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchApi = async (url: string, method: string, data?: any) => {
  const urlFetch = `${BASE_URL}${url}`;
  try {
    const response = await axiosInstance(urlFetch, {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data ? data : undefined,
    });

    return response.data;

  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      console.error('Error fetching data', error.message);
      return error.response?.data;
    } else {
      console.error('Unknown error occurred', error);
      throw new Error('An unknown error occurred');
    }
  }
};
