/* eslint-disable @typescript-eslint/no-explicit-any */
type Actions =
| { type: 'cleanUser' }
| { type: 'setLanguage', payload: string } // Nueva acción para el idioma
| { type: 'setFieldData', payload: any }; // Acción para disponibilizar la info
// Otros tipos de acción ...

export const dataReducer = (state: any, action: Actions) => {
  switch (action.type) {
    // Nuevo caso para cambiar el idioma
    case 'setLanguage':
      return {
        ...state,
        language: action.payload, // Guardamos el nuevo idioma en el estado
      };

      // Caso para disponibilizar o actualizar información
    case 'setFieldData':
      return {
        ...state,
        fieldData: action.payload, // Guardamos o actualizamos la info
      };

      // Otros casos existentes ...

    default:
      return state;
  }
};
