import React, { useEffect } from 'react';
import { Layout } from 'antd';

import Top from '../../assets/img/CencoDecoration.svg';
import Bottom from '../../assets/img/CencoDecoration_down.svg';

import './sidebar.css';

const { Sider } = Layout;

interface SidebarProps {
  isVisible: boolean;
}

const CustomSidebar: React.FC<SidebarProps> = ({ isVisible }) => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!isVisible || isMobile) return null;

  return (
    <Sider
      width="35vw"
      collapsedWidth='0'
      className='custom-sidebar'
      theme='light'
    >
      <div className='sidebar-background'>
        {/* Imagen Superior */}
        <img src={ Top } alt="Top" className='sidebar-top-image' />
        {/* Imagen Inferior */}
        <img src={ Bottom } alt="Bottom" className='sidebar-bottom-image' />
      </div>
    </Sider>
  );
};

export default CustomSidebar;
