import React, { useContext } from 'react';

import CircleCheck from '../../assets/img/circle-check.svg';
import ImportantIcon from '../../assets/img/info-circle.svg';
import Pin from '../../assets/img/map-pin.svg';
import Clock from '../../assets/img/clock.svg';
import Identy from '../../assets/img/id.svg';
import Mail from '../../assets/img/mail.svg';

import './success.css'; // Importar los estilos
import { DataContext } from '../../context/DataContext';
import dict from '../../dict';
import CustomFooter from '../../components/Footer/index';
import { useLocation } from 'react-router-dom';

interface LocationState {
  email: string;
}

const SuccessPage: React.FC = () => {

  const { dataState } = useContext(DataContext);
  const { language } = dataState;

  const location = useLocation();
  const state = location.state as LocationState;
  const email = state?.email || 'email@email.com';

  // Accede a los textos del diccionario dependiendo del idioma seleccionado
  const success = dict.success[language as keyof typeof dict.home];

  return (
    <>
      <div className="success-container">
        <div className="success-content">
          <div className="icon-check">
            <img src={ CircleCheck } alt="" />
          </div>
          <div className="success-title">
            <h1>{ success.registerTitle }</h1>
            <p>
              <strong>{ success.thanks }</strong>
            </p>
            <p>
              { success.email } <strong>{ email }</strong>
            </p>
          </div>
          <div className="steps-container">
            <div className="important-message">
              <img src={ ImportantIcon } alt="Important" />
              <div className='important-contain'>
                <p>
                  <strong>{ success.important }</strong>
                </p>
                <p>{ success.importantInfo }</p>
              </div>
            </div>

            <div className="step-container-line">
              <div className="step">
                <div className="step-number-container">
                  <div className="step-number"><strong>1</strong></div>
                  <p>
                    <strong>
                      { success.step1 }
                    </strong>
                  </p>
                </div>
                <div className='step-container'>
                  <img src={ Pin } alt="pin" />
                  <p>{ success.step1Info1 }</p>
                </div>
                <div className='step-container'>
                  <img src={ Clock } alt="clock" />
                  <p>{ success.step1Info2 }</p>
                </div>
              </div>

              <div className="step">
                <div className="step-number-container">
                  <div className="step-number"><strong>2</strong></div>
                  <p>
                    <strong>
                      { success.step2 }
                    </strong>
                  </p>
                </div>
                <div className='step-container'>
                  <img src={ Identy } alt="identy" />
                  <p>{ success.step2Info1 }</p>
                </div>
                <div className='step-container'>
                  <img src={ Mail } alt="mail" />
                  <p>{ success.step2Info2 }</p>
                </div>
              </div>

              <div className="step">
                <div className="step-number-container">
                  <div className="step-number"><strong>3</strong></div>
                  <p>
                    <strong>
                      { success.setp3 }
                    </strong>
                  </p>
                </div>
                <div className='step-container'>
                  <img src={ Pin } alt="pin" />
                  <p>{ success.setp3Info }</p>
                </div>
              </div>
              <div className="success-footer-container">
                <CustomFooter isVisible={true} />
              </div>
            </div>


          </div>
        </div>
      </div>
      <div className="success-background-svg-top"></div>
      <div className="success-background-svg-bottom"></div>
    </>
  );
};

export default SuccessPage;
